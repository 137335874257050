import React, {Component} from 'react'
import { Popover } from 'antd'

class OrderAddress extends Component {
    render() {
        const {country = '', email = '', full_name = '', zone = ''} = this.props

        return (
            <td className="OrderAddress">
                {
                    !!full_name &&
                    <div>{full_name}</div>
                }
                {
                    !!email &&
                    <div>
                        <Popover
                            placement="topLeft"
                            content={email}
                            overlayClassName ='emailPopover'
                            // title={`Order note`}
                        >
                            <span className='cursor-pointer'>{`${email.substring(0, 15)}...`}</span>
                        </Popover>
                    </div>
                }
                {
                    !!country &&
                    <div className="Country">
                        <span>{country}</span>
                        {!!zone && <span> ({zone})</span>}
                    </div>
                }
            </td>
        )
    }
}

export default OrderAddress
