import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { Solutions } from '../../package-issues/helps/ListOptionSelect'
import { Tooltip, message, Modal, Input, Select, Button } from 'antd'
import { getLocalData } from '../../../services/StorageServices'
import { getIssueType } from '../../../services/api/PackageIssuesServices'
import { getListCarriers } from '../../../services/api/ShippingServices'
import RefundModal from './RefundModal'
import ListItemsNote from './ListItemsNote'
import IssueReferenceNumber from './IssueItemsTable/IssueReferenceNumber'

const { TextArea } = Input

function IssueItemsTable(props) {
    const { details, handleDelete, setListIssueItems, isSupplierChanged, detailsBefore, issueCat, isSupplierAllow, id, statusPackage, orderId, setIsOpenRefund, isOpenRefund, supplier } = { ...props }
    const [isOpen, setIsOpen] = useState(false)
    const [keyModal, setKeyModal] = useState('')
    const [issueTypeShipments, setIssueTypeShipments] = useState([])
    const [issueTypeProductions, setIssueTypeProductions] = useState([])
    const [shippingCarriers, setShippingCarriers] = useState([])
    const [originalCarriers, setOriginalCarriers] = useState([])

    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const canCreate = permissionsData.includes('ffm_upsert_issue')
    const canEdit = permissionsData.includes('ffm_upsert_issue')

    const _fetchCarrier = async () => {
        try {
            const { data, success, message } = await getListCarriers()
            if (!success) {
                return console.log(message)

            }
            setShippingCarriers(data.shippingCarriers.map(i => ({ label: i, value: i })))
            setOriginalCarriers(data.trackingCompanies.map(i => ({ label: i, value: i })))
        } catch (error) {
            console.log(error)
        }
    }

    const fetchIssueTypes = async () => {
        try {
            const { success, data, message: mess } = await getIssueType()
            if (!success) {
                throw new Error(mess)
            }
            const { production, shipment } = { ...data }

            const newProduction = !!production && production.length > 0 ?
                production.map(i => ({ value: i._id, label: i.name })) : []

            const newShipping = !!shipment && shipment.length > 0 ?
                shipment.map(i => ({ value: i._id, label: i.name })) : []

            setIssueTypeProductions(newProduction)
            setIssueTypeShipments(newShipping)
        } catch (error) {
            message.error(error.message)
        }
    }

    useEffect(() => {
        fetchIssueTypes()
        _fetchCarrier()
    }, [])

    const handleChangeInput = (name, value, index) => {
        const newList = details.map((i, idx) => {
            if (idx === index) {
                if (!!value) {
                    return {
                        ...i,
                        [name]: value
                    }
                } else {
                    delete i[name]
                    return i
                }
            } return i
        })

        setListIssueItems(newList)
    }

    const handleChangeSelect = (name, value, index, field_parent) => {
        const newList = details.map((i, idx) => {
            if (idx === index) {
                if (!!field_parent) {
                    return {
                        ...i,
                        [field_parent]: !!i[field_parent] ? {
                            ...i[field_parent],
                            [name]: value
                        } : {
                            [name]: value
                        }
                    }
                }
                return {
                    ...i,
                    [name]: value
                }
            } return i
        })
        setListIssueItems(newList)
    }

    const handleDeleteItem = (e, id, index) => {
        e.preventDefault()
        Modal.confirm({
            title: <span className='text-danger fs-14'>Confirm Delete</span>,
            icon: '',
            content: 'Are you sure you want to delete?',
            okText: 'Ok',
            cancelText: 'Cancel',
            onOk: () => handleDelete(id, index)
        })
    }

    const hasLinks = (text) => {
        const regex = /https?:\/\/[^\s]+/g
        return regex.test(text)
    }

    const openAnyLinks = (text) => {
        const regex = /https?:\/\/[^\s]+/g
        const links = text.match(regex)
        if (links) {
            links.forEach((link, index) => {
                window.open(link, '_blank')
            })
        }
    }

    const handleOpenModalPartnerConfirm = (index) => {
        setKeyModal(index)
        setIsOpen(true)
    }

    const handleCloseModalPartnerConfirm = () => {
        setIsOpen(false)
    }

    const handleChangeInputPartnerConfirm = (field, value, index) => {
        const newListIssueItems = details.map((item, i) => {
            const { factory_info = {} } = Object.assign({}, item)
            if (i === index) {
                return {
                    ...item,
                    factory_info: {
                        ...factory_info,
                        [field]: value
                    }
                }
            }
            return item
        })
        setListIssueItems(newListIssueItems)
    }

    const handleSavePartnerConfirm = (index) => {
        if (details.some((item, i) => {
            if (i === index) {
                const { factory_info } = Object.assign({}, item)
                const { confirm } = Object.assign({}, factory_info)
                if (!confirm) return true
                return false
            }
        })) return message.error("Partner confirm is require!")

        handleCloseModalPartnerConfirm()
        message.success("Confirm successfully!")
    }

    return (
        <div>
            <table className='IssueItemsTable table'>
                <thead>
                    <tr>
                        <th>Reference number</th>
                        <th>Supplier</th>
                        {issueCat === "production" && <th>Product type</th>}
                        {issueCat === "production" && <th>Variant</th>}
                        {issueCat === "production" && <th>Quantity</th>}
                        <th>Tracking {issueCat === "shipment" && isSupplierAllow && <span className="text-danger">*</span>}</th>
                        {issueCat === "shipment" && <th>Carrier {isSupplierAllow && <span className="text-danger">*</span>}</th>}
                        <th>Issue type {!(issueCat === "production" && (isSupplierAllow || !supplier)) && <span className="text-danger">*</span>}</th>
                        {issueCat === "production" && <th style={{ width: 105 }}>Error qty <span className="text-danger">*</span></th>}
                        <th>Note</th>
                        <th>FFM's proof</th>
                        <th>Solution <span className="text-danger">*</span></th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {!!details && details.length > 0 ? details.map((i, index) => {
                        const isItemSolutionNone = detailsBefore.some((iBefore) => (iBefore._id === i._id && iBefore.solution === 'none'))
                        const issue = detailsBefore.find((iBefore) => (iBefore._id === i._id)) || {}
                        const { type: issueType = {} } = Object.assign({}, issue)
                        const { name: typeName = '' } = Object.assign({}, issueType)

                        const { supplier, reference_number, variant_data, tracking_number, pushed_at, quantity, error_qty,
                            note, ffm_proof, solution, error, factory_info, type, shipment_info, status, metadata, solution_note,
                        } = Object.assign({}, i)
                        const { name } = Object.assign({}, supplier)
                        const { product_type, size, color } = Object.assign({}, variant_data)
                        const { confirm: confirmPartner, note: notePartner, proof: proofPartner } = Object.assign({}, factory_info)
                        const { origin_carrier, shipping_carrier } = Object.assign({}, shipment_info)
                        const { source = '' } = Object.assign({}, metadata)
                        const isIssueImport = source === "import"
                        // const canDelete = ['1C', 'TCVN', 'MCUS'].includes(name) ? (issueCat === 'production' && (!status || ['pending', 'approved'].includes(status))) ||
                        //     (issueCat === 'shipment' && (['MCUS'].includes(name) ? (!status || ['approved'].includes(status)) : (!status || ['pending', 'confirmed'].includes(status)))) : (!status || ['confirmed'].includes(status))
                        const checkDelete = () => {
                            return (['1C', 'TCVN', 'MCUS'].includes(name) ?
                                (issueCat === "production" ?
                                    (!status || ['pending', 'approved'].includes(status)) :
                                    (['MCUS'].includes(name) ?
                                        (!status || ['approved'].includes(status)) :
                                        (!status || ['pending', 'confirmed'].includes(status))
                                    )
                                ) : (!status || ['confirmed'].includes(status)))
                        }

                        return (
                            <tr key={`items_${index}`}>
                                <td style={{ verticalAlign: 'top' }}>
                                    <IssueReferenceNumber
                                        reference_number={reference_number}
                                        index={index}
                                        pushed_at={pushed_at}
                                        details={details}
                                    />
                                </td>
                                <td style={{ verticalAlign: 'top' }}>{name}</td>
                                {issueCat === "production" && <td style={{ verticalAlign: 'top' }}>{product_type}</td>}
                                {issueCat === "production" && <td style={{ verticalAlign: 'top' }}>{(size || color) ?
                                    `${size || ''}${size && color ? '/' : ''}${color || ''}` : ''
                                }
                                </td>}
                                {issueCat === "production" && <td style={{ verticalAlign: 'top' }}>{quantity}</td>}
                                <td className='text-primary' style={{ verticalAlign: 'top' }}>
                                    {
                                        ((status === "pending" && !isIssueImport && issueCat === "shipment" && isSupplierAllow) || (issueCat === "shipment" && isSupplierAllow && !status)) ?
                                            <Input
                                                placeholder='Tracking number'
                                                value={tracking_number || undefined}
                                                allowClear
                                                onChange={e => handleChangeInput('tracking_number', e.target.value, index)}
                                                disabled={status === "done"}
                                            /> :
                                            tracking_number
                                    }
                                </td>
                                {
                                    issueCat === "shipment" &&
                                    <td style={{ verticalAlign: 'top' }}>
                                        {
                                            ((status === "pending" && !isIssueImport && issueCat === "shipment" && isSupplierAllow) || (issueCat === "shipment" && isSupplierAllow && !status)) ?
                                                <div className="d-flex flex-column">
                                                    <Select
                                                        className='mb-1'
                                                        placeholder='Shipping carrier'
                                                        value={shipping_carrier || []}
                                                        style={{ minWidth: 150 }}
                                                        showArrow
                                                        options={shippingCarriers}
                                                        onChange={(value) => handleChangeSelect('shipping_carrier', value, index, 'shipment_info')}
                                                        filterOption={(inputValue, option) =>
                                                            !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                        disabled={status === "done"}
                                                    />
                                                    <Select
                                                        placeholder='Origin carrier'
                                                        value={origin_carrier || []}
                                                        style={{ minWidth: 150 }}
                                                        showArrow
                                                        options={originalCarriers}
                                                        onChange={(value) => handleChangeSelect('origin_carrier', value, index, 'shipment_info')}
                                                        filterOption={(inputValue, option) =>
                                                            !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                        disabled={status === "done"}
                                                    />
                                                </div> :
                                                <div className="d-flex flex-column">
                                                    {shipping_carrier && <span>{shipping_carrier}</span>}
                                                    {origin_carrier && <span>{origin_carrier}</span>}
                                                </div>
                                        }
                                    </td>
                                }
                                <td style={{ verticalAlign: 'top' }}>
                                    {
                                        ((status === "pending" && !(issueCat === "production" && (isSupplierAllow || ['1C', 'TCVN', 'MCUS'].includes(name)))) || (issueCat === "production" && !(isSupplierAllow || ['1C', 'TCVN', 'MCUS'].includes(name)) && !status) || (issueCat === "shipment" && !status)) ?
                                            <Select
                                                placeholder='Issue type'
                                                value={type || []}
                                                style={{ minWidth: 150 }}
                                                disabled={status === 'done' || isIssueImport}
                                                showArrow
                                                options={issueCat === "shipment" ? issueTypeShipments : issueTypeProductions}
                                                onChange={(value) => handleChangeSelect('type', value, index)}
                                                filterOption={(inputValue, option) =>
                                                    !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            /> : typeName
                                    }
                                </td>
                                {
                                    (!status || ['pending', 'approved', 'checking', 'confirmed'].includes(status)) && (canCreate || canEdit) ?
                                        !status || ['pending'].includes(status) ?
                                            <>
                                                {issueCat === "production" && <td style={{ verticalAlign: 'top' }}>
                                                    <Input
                                                        placeholder='Error qty'
                                                        value={error_qty || undefined}
                                                        allowClear
                                                        onChange={e => handleChangeInput('error_qty', e.target.value, index)}
                                                        type='number'
                                                        disabled={isIssueImport}
                                                    />
                                                </td>}
                                                <td style={{ verticalAlign: 'top' }}>
                                                    <TextArea
                                                        placeholder='Note'
                                                        value={note || undefined}
                                                        allowClear
                                                        rows={1}
                                                        onChange={e => handleChangeInput('note', e.target.value, index)}
                                                        disabled={isIssueImport}
                                                    />
                                                </td>
                                                <td style={{ verticalAlign: 'top' }}>
                                                    <Input
                                                        placeholder={`FFM's proof`}
                                                        value={ffm_proof || undefined}
                                                        allowClear
                                                        onChange={e => handleChangeInput('ffm_proof', e.target.value, index)}
                                                        disabled={isIssueImport}
                                                    />
                                                </td>

                                                <td style={{ verticalAlign: 'top' }}>
                                                    <Select
                                                        placeholder='Solution'
                                                        value={solution || []}
                                                        style={{ minWidth: 95 }}
                                                        showArrow
                                                        options={Solutions}
                                                        onChange={(value) => handleChangeSelect('solution', value, index)}
                                                        filterOption={(inputValue, option) =>
                                                            !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                        disabled={isIssueImport}
                                                    />
                                                    <ListItemsNote
                                                        key={'PopoverNote_' + index}
                                                        id={'PopoverNote_' + index}
                                                        note={solution_note}
                                                        itemId={i._id}
                                                        title='Solution note'
                                                        itemIndex={index}
                                                        onChangeInput={handleChangeInput}
                                                    />
                                                    {error}
                                                </td>
                                            </>
                                            : isItemSolutionNone ?
                                                <>
                                                    {issueCat === "production" && <td style={{ verticalAlign: 'top' }}>{error_qty}</td>}
                                                    <td style={{ verticalAlign: 'top' }}>
                                                        {!!note ?
                                                            <Tooltip title={note}>
                                                                {note.length > 10 ? note.substring(0, 10) + '...' : note}
                                                            </Tooltip>
                                                            : ''
                                                        }
                                                    </td>
                                                    <td style={{ verticalAlign: 'top' }}>
                                                        {ffm_proof ?
                                                            hasLinks(ffm_proof) ?
                                                                <Tooltip title={ffm_proof}>
                                                                    <a className='text-primary' onClick={() => openAnyLinks(ffm_proof)}>View</a>
                                                                </Tooltip> :
                                                                <Tooltip title={ffm_proof}>
                                                                    {ffm_proof.length > 10 ? ffm_proof.substring(0, 10) + '...' : ffm_proof}
                                                                </Tooltip>
                                                            : ''
                                                        }
                                                    </td>
                                                    <td style={{ verticalAlign: 'top' }}>
                                                        <Select
                                                            placeholder='Solution'
                                                            value={solution || []}
                                                            style={{ minWidth: 95 }}
                                                            showArrow
                                                            options={Solutions}
                                                            onChange={(value) => handleChangeSelect('solution', value, index)}
                                                            filterOption={(inputValue, option) =>
                                                                !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                            }
                                                        />
                                                        <ListItemsNote
                                                            key={i._id}
                                                            id={'PopoverNote_' + i._id}
                                                            note={solution_note}
                                                            itemId={i._id}
                                                            title='Solution note'
                                                            itemIndex={index}
                                                            onChangeInput={handleChangeInput}
                                                        />
                                                        {error}
                                                    </td>
                                                </>
                                                :
                                                <>
                                                    {issueCat === "production" && <td style={{ verticalAlign: 'top' }}>{error_qty}</td>}
                                                    <td style={{ verticalAlign: 'top' }}>
                                                        {!!note ?
                                                            <Tooltip title={note}>
                                                                {note.length > 10 ? note.substring(0, 10) + '...' : note}
                                                            </Tooltip>
                                                            : ''
                                                        }
                                                    </td>
                                                    <td style={{ verticalAlign: 'top' }}>
                                                        {ffm_proof ?
                                                            hasLinks(ffm_proof) ?
                                                                <Tooltip title={ffm_proof}>
                                                                    <a className='text-primary' onClick={() => openAnyLinks(ffm_proof)}>View</a>
                                                                </Tooltip> :
                                                                <Tooltip title={ffm_proof}>
                                                                    {ffm_proof.length > 10 ? ffm_proof.substring(0, 10) + '...' : ffm_proof}
                                                                </Tooltip>
                                                            : ''
                                                        }
                                                    </td>
                                                    <td className='text-capitalize' style={{ verticalAlign: 'top' }}>
                                                        {solution}
                                                        <ListItemsNote
                                                            key={i._id}
                                                            id={'PopoverNote_' + i._id}
                                                            note={solution_note}
                                                            itemId={i._id}
                                                            title='Solution note'
                                                            itemIndex={index}
                                                            onChangeInput={handleChangeInput}
                                                        />
                                                    </td>
                                                </>
                                        :
                                        <>
                                            {issueCat === "production" && <td style={{ verticalAlign: 'top' }}>{error_qty}</td>}
                                            <td style={{ verticalAlign: 'top' }}>
                                                {!!note ?
                                                    <Tooltip title={note}>
                                                        {note.length > 10 ? note.substring(0, 10) + '...' : note}
                                                    </Tooltip>
                                                    : ''
                                                }
                                            </td>
                                            <td style={{ verticalAlign: 'top' }}>
                                                {ffm_proof ?
                                                    hasLinks(ffm_proof) ?
                                                        <Tooltip title={ffm_proof}>
                                                            <a className='text-primary' onClick={() => openAnyLinks(ffm_proof)}>View</a>
                                                        </Tooltip> :
                                                        <Tooltip title={ffm_proof}>
                                                            {ffm_proof.length > 10 ? ffm_proof.substring(0, 10) + '...' : ffm_proof}
                                                        </Tooltip>
                                                    : ''
                                                }
                                            </td>
                                            <td className='text-capitalize' style={{ verticalAlign: 'top' }}>{solution}</td>
                                            <ListItemsNote
                                                key={i._id}
                                                id={'PopoverNote_' + i._id}
                                                note={solution_note}
                                                itemId={i._id}
                                                title='Solution note'
                                                itemIndex={index}
                                                onChangeInput={handleChangeInput}
                                            />
                                        </>
                                }
                                <td style={{ verticalAlign: 'top' }}>
                                    {
                                        (!(issueCat === "production" && (isSupplierAllow || ['1C', 'TCVN', 'MCUS'].includes(name))) && !['MCUS'].includes(name)) &&
                                        <Tooltip title="Partner's confirm">
                                            <i
                                                className="fa fa-plus-circle cursor-pointer fa-lg mr-2"
                                                onClick={() => handleOpenModalPartnerConfirm(index)}
                                                style={{
                                                    opacity: !(isSupplierAllow || ['1C', 'TCVN'].includes(name)) ? 1 : 0.2,
                                                }}
                                            />
                                            <Modal
                                                title={<span style={{ fontWeight: 'bold' }}>Partner's confirm</span>}
                                                visible={isOpen && keyModal === index}
                                                onOk={() => handleSavePartnerConfirm(index)}
                                                onCancel={handleCloseModalPartnerConfirm}
                                                footer={[
                                                    <Button key="back" onClick={handleCloseModalPartnerConfirm}>Cancel</Button>,
                                                    <Button
                                                        key="submit"
                                                        type="primary"
                                                        disabled={i.status === "done"}
                                                        onClick={() => handleSavePartnerConfirm(index)}
                                                    >
                                                        Save
                                                    </Button>
                                                ]}
                                            >
                                                <div className="supplier-confirm mb-2">
                                                    <div className='font-weight-bold mb-1'>Partner's confirm <span className='text-danger'>*</span></div>
                                                    <Select
                                                        placeholder="Choose partner's confirm"
                                                        value={confirmPartner || []}
                                                        onChange={value => handleChangeInputPartnerConfirm('confirm', value, index)}
                                                        className='custom-input-partnerConfirm'
                                                        style={{ width: '100%' }}
                                                        disabled={i.status === "done"}
                                                    >
                                                        <Select.Option value="approve">Approve</Select.Option>
                                                        <Select.Option value="reject">Reject</Select.Option>
                                                        <Select.Option value="half">50/50</Select.Option>
                                                        <Select.Option value="none">None</Select.Option>
                                                    </Select>
                                                </div>
                                                <div className="note-confirm mb-2">
                                                    <div className='font-weight-bold mb-1'>Note</div>
                                                    <TextArea
                                                        rows={3}
                                                        value={notePartner}
                                                        onChange={e => handleChangeInputPartnerConfirm('note', e.target.value, index)}
                                                        className='custom-input-partnerConfirm'
                                                        disabled={i.status === "done"}
                                                    />
                                                </div>
                                                <div className="note-confirm">
                                                    <div className='font-weight-bold mb-1'>Partner's proof</div>
                                                    <Input
                                                        value={proofPartner}
                                                        onChange={e => handleChangeInputPartnerConfirm('proof', e.target.value, index)}
                                                        className='custom-input-partnerConfirm'
                                                        disabled={i.status === "done"}
                                                    />
                                                </div>
                                            </Modal>
                                        </Tooltip>
                                    }
                                    {checkDelete() &&
                                        (canCreate || canEdit) &&
                                        <Tooltip title='Delete'>
                                            <i className='fa fa-close fa-lg text-danger cursor-pointer' onClick={(e) => handleDeleteItem(e, i, index)} />
                                        </Tooltip>
                                    }
                                </td>
                            </tr>
                        )
                    }) :
                        <tr>
                            <td colSpan={11}>No Issue item</td>
                        </tr>
                    }
                </tbody>
            </table>
            {
                isOpenRefund &&
                <RefundModal
                    packageName={id}
                    isOpenRefund={isOpenRefund}
                    statusPackage={statusPackage}
                    closeModal={() => setIsOpenRefund(false)}
                    details={details}
                    orderId={orderId}
                    issueCat={issueCat}
                />
            }
        </div>
    )
}

export default IssueItemsTable