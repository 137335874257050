
import React, { useState, useEffect, useMemo } from 'react'
import { message, Tooltip, Modal, Select, Button, Checkbox, Spin } from 'antd'
import { approveIssue, deletePackageIssue } from '../../../../services/api/PackageIssuesServices'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Truncate from '../../../../helpers/common/formatNote'
import { IssueCategorys, Accepts } from '../../helps/ListOptionSelect'
import { ObjectInspector } from 'react-inspector'
import { searchOrderHistories } from '../../../../services/api/OrdersServices'
import humanizeTime from '../../../../helpers/time/humanizeTime'
import ListItemsNote from '../ListItemsNote'


function PackageIssuesRow(props) {
    const { item = {}, refresh, permissionsData, ProductionIssueTypes, ShipmentIssueTypes, refs, listSelecteds, handleSelected } = { ...props }
    const { col1Ref, col2Ref, col3Ref } = refs
    const {
        _id, package_name, reference_number, variant_data, fulfillment,
        type, accepted, solution, status, created_at, approved_at, category,
        created_by, push_at, approved_by, ffm_proof, error_qty, partner_refunded,
        supplier = {}, namespace, note, factory_info, resolution, shipment_info, metadata
    } = { ...item }
    const { product_type, size } = Object.assign({}, variant_data)
    const { shipping_carrier = '', origin_carrier = '' } = Object.assign({}, shipment_info)
    const { source = '' } = Object.assign({}, metadata)
    const isSupplierAllow = ['1C', 'TCVN', 'MCUS'].includes(supplier.sku_prefix)
    const isDelete = isSupplierAllow && category === "production"

    const { order = '' } = Object.assign({}, fulfillment)
    const { name, _id: typeId } = { ...type }
    const [issueCategory, setIssueCategoty] = useState('')
    const [issueType, setIssueType] = useState('')
    const [newAccepted, setNewAccepted] = useState('')
    const [approveModal, setApproveModal] = useState(false)
    const [showHisory, setShowHistory] = useState(false)
    const [history, setHistory] = useState([])
    const [error, setError] = useState('')
    const [partnerRefunded, setPartnerRefunded] = useState(false)
    const [loadingHistory, setLoadingHistory] = useState(false)
    const [loadingApprove, setLoadingApprove] = useState(false)
    const [showGroup, setShowGroup] = useState(false)
    const [loadingGroup, setLoadingGroup] = useState(false)

    useEffect(() => {
        if (col1Ref && col1Ref.current) {
            let left2 = col1Ref.current.offsetWidth
            col2Ref.current.style.left = `${BulkActionsCheck ? left2 + 70 : left2}px`
        }
        if (col1Ref && col2Ref && col1Ref.current && col2Ref.current) {
            let left3 = col1Ref.current.offsetWidth + col2Ref.current.offsetWidth
            col3Ref.current.style.left = `${BulkActionsCheck ? left3 + 70 : left3}px`
        }
    })

    const _handleHistoryClick = async (object_id) => {
        setLoadingHistory(true)
        setHistory([])
        try {
            const payload = {
                object_type: "FulfillmentIssue",
                object_id: object_id,
                page: 1,
                limit: 10000
            }
            const { data, success, message } = await searchOrderHistories(payload)
            if (!success) throw new Error(message)
            setShowHistory(true)
            setHistory(data.histories || [])
        } catch (error) {
            message.error(error.message)
        } finally {
            setLoadingHistory(false)
        }
    }
    const _onCloseHistory = () => {
        setShowHistory(false)
        setHistory([])
    }

    const handleShowModal = () => {
        setIssueCategoty(category || '')
        setIssueType(typeId || '')
        setNewAccepted((accepted === null ? '' : (accepted ? 'yes' : 'no')))
        setApproveModal(true)
        setPartnerRefunded(partner_refunded || false)
    }

    const handleCloseModal = () => {
        setApproveModal(false)
        setIssueCategoty('')
        setIssueType('')
        setNewAccepted('')
        setPartnerRefunded(false)
    }
    const onApprove = async () => {
        setLoadingApprove(true)
        const payload = {
            accepted: !newAccepted ? null : (newAccepted === 'yes' ? true : false),
            issue_type: issueType,
            partner_refunded: partnerRefunded ? 1 : 0
        }

        try {
            const { success, data, message: mess } = await approveIssue(payload, _id)
            if (!success) {
                throw new Error(mess)
            }
            message.success('Approve success!')
            refresh()
            handleCloseModal()
        } catch (error) {
            message.error(error.message)
        } finally {
            setLoadingApprove(false)
        }
    }

    const handleChangeSelect = (field, value) => {
        if (field === 'issueCategory') {
            setIssueCategoty(value)
            setIssueType('')
        }
        if (field === 'issueType') {
            if (!issueCategory) {
                const isProduction = ProductionIssueTypes.find(i => i.value === value)
                const isShipment = ShipmentIssueTypes.find(i => i.value === value)
                if (isProduction && isShipment) {
                    setIssueType(value)
                    return
                } else {
                    isProduction && setIssueCategoty('production')
                    isShipment && setIssueCategoty('shipment')
                }
            }
            setIssueType(value)
        }
        if (field === 'newAccepted') setNewAccepted(value)
    }

    const handleChangePartnerRefunded = e => {
        const { checked } = e.target
        setPartnerRefunded(checked)
    }


    const _handleDelete = async (id) => {
        try {
            const res = await deletePackageIssue(id)

            const { message: mess, success } = res
            if (!success) {
                throw new Error(mess)
            }
            message.success('Deleted successfully!')
            refresh()
        } catch (e) {
            message.error(e.message || 'Unknown error.')
        }
    }

    const handleClickDelete = (e, id) => {
        e.preventDefault()
        Modal.confirm({
            title: <span className='text-danger fs-14'>Confirm Delete</span>,
            icon: '',
            content: 'Are you sure you want to delete?',
            okText: 'Ok',
            cancelText: 'Cancel',
            onOk: () => _handleDelete(id)
        })

    }

    const openAnyLinks = (text) => {
        const regex = /https?:\/\/[^\s]+/g
        const links = text.match(regex)
        if (links) {
            links.forEach((link, index) => {
                window.open(link, '_blank')
            })
        }
    }

    const handleShowGroup = () => {
        setShowGroup(true)
    }

    const handleCloseGroup = () => {
        setShowGroup(false)
    }

    const hasLinks = (text) => {
        const regex = /https?:\/\/[^\s]+/g
        return regex.test(text)
    }

    let IssueType = []

    switch (issueCategory) {
        case 'production':
            IssueType = ProductionIssueTypes
            break
        case 'shipment':
            IssueType = ShipmentIssueTypes
            break
        default:
            IssueType = [...new Set([...ProductionIssueTypes, ...ShipmentIssueTypes])].reduce((acc, current) => {
                const x = acc.find(item => item.name === current.name)
                if (!x) {
                    return acc.concat([current])
                } else {
                    return acc
                }
            }, []).sort((a, b) => a.name.localeCompare(b.name))
            break
    }

    const _detectChecked = () => {
        return listSelecteds.includes(item._id)
    }

    const canEdit = permissionsData.includes('ffm_upsert_issue')
    const canDelete = permissionsData.includes('ffm_delete_issue')
    const canViewDetails = permissionsData.includes('ffm_issue_list')
    const BulkActionsCheck = permissionsData.includes('ffm_mark_issue_done') || permissionsData.includes('ffm_mark_issue_replaced') || permissionsData.includes('ffm_mark_issue_refunded') || permissionsData.includes('ffm_mark_issue_partner_refunded')
    const disableApprove = loadingApprove
    const disableApproveBtn = (issueCategory === "production" && isSupplierAllow) ? (!issueCategory || !issueType || !newAccepted) : (!issueCategory || !issueType)
    const allowEditIssueType = (category && (['1C', 'TCVN', 'MCUS'].includes(supplier.name)) && status === "pending")
    const tooltipStatus = {
        pending: 'Issue has been created and is awaiting approval.',
        approved: 'Issue has been approved and sent to factory.',
        checking: 'Issue has been checked to factory and being in progress.',
        confirmed: 'Issue has been confirmed by factory.',
        done: 'Issue completed.',
        closed: 'Issue has been closed as user changed supplier.'
    }

    const col2Left = col1Ref && col1Ref.current ? col1Ref.current.offsetWidth : 135
    const col3Left = col1Ref && col2Ref && col1Ref.current && col2Ref.current ? col1Ref.current.offsetWidth + col2Ref.current.offsetWidth : 301
    const left2 = (BulkActionsCheck ? col2Left + 70 : col2Left)
    const left3 = (BulkActionsCheck ? col3Left + 70 : col3Left)

    const checkDelete = () => {
        return (isSupplierAllow ?
            (category === "production" ?
                (!status || ['pending', 'approved'].includes(status)) :
                (['MCUS'].includes(supplier.sku_prefix) ?
                    (!status || ['approved'].includes(status)) :
                    (!status || ['pending', 'confirmed'].includes(status))
                )
            ) : (!status || ['confirmed'].includes(status)))
    }

    return (
        <tr>
            {BulkActionsCheck &&
                <td className='fixed-column0' style={{ minWidth: '70px', left: 0 }}><Checkbox checked={_detectChecked()} onChange={(e) => handleSelected(_id, e.target.checked)} /></td>
            }
            <td ref={col1Ref} className='fixed-column1' style={{ left: (BulkActionsCheck ? 70 : 0) }}>
                <div>
                    <div className="Packagename">
                        {
                            !!order ?
                                <Link to={`/a/orders/${order}`} target="_blank" className='font-weight-bold' style={{ color: '#007bff' }}>
                                    {package_name}
                                </Link> :
                                <span className='font-weight-bold' style={{ color: '#007bff' }}>
                                    {package_name}
                                </span>
                        }
                    </div>
                    <div className="Store">
                        {namespace}
                    </div>
                    <div className="tags d-flex flex-wrap">
                        <span className="SupplierName badge badge-secondary">
                            {supplier && supplier.name}
                        </span>
                        {accepted &&
                            <span className="SupplierName badge mx-1 badge-secondary" style={{ background: '#87cc8b' }}>
                                acceptable
                            </span>
                        }
                    </div>
                </div>
            </td>
            <td ref={col2Ref} className='fixed-column2' style={{ left: left2 }}>
                {typeof reference_number === 'object' && reference_number && reference_number.length > 0 ?
                    reference_number.map((i, idx) => idx === 0 && (
                        <div key={`reference_number_${idx}`} className="referenceNumber cursor-pointer" style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }} onClick={() => _handleHistoryClick(_id)}>
                            <Tooltip title='History' placement='topRight'>{i}</Tooltip>
                        </div>
                    )) :
                    <div className="referenceNumber cursor-pointer" style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }} onClick={() => _handleHistoryClick(_id)}>
                        <Tooltip title='History' placement='topRight'>{reference_number}</Tooltip>
                    </div>
                }
                <div className="pushedAt">{push_at ? moment(push_at).format('HH:mm DD/MM/YYYY') : ''}</div>
                <Modal
                    visible={showHisory}
                    width={800}
                    title='Issues History'
                    onCancel={_onCloseHistory}
                    className='IssuesHistoryModal'
                    footer={[
                        error && <p key='error' className="text-danger">{error}</p>,
                        <Button className="cancel" key="cancel" onClick={_onCloseHistory} disabled={loadingHistory}>Đóng</Button>,
                    ]}
                >
                    {!!history && history.length > 0 ? (<ul>
                        {history.map(h => {
                            const {
                                _id: historyId,
                                event,
                                created_at: createdAt,
                                description,
                                meta, user,
                            } = h
                            const hdata = { ...meta, user, date_time: moment(createdAt).format('DD/MM/YYYY hh:mm:ss') }

                            return <li className="card" key={historyId}>
                                <div className="card-header">
                                    <time title={createdAt}>{humanizeTime(createdAt)}</time>
                                </div>
                                <div className="card-body">
                                    <div className="row align-items-baseline mb-3">
                                        <div className="col-4"><code>{event}</code>
                                        </div>
                                        <div className="col-8 text-muted">{description}</div>
                                    </div>
                                    <div className="row align-items-baseline">
                                        <div className="col-4">Value</div>
                                        <div className="col-8">
                                            <ObjectInspector depth={0} patxh="root.value" data={hdata} />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        })
                        }
                    </ul>) : (<h6 className='text-danger'>No history data has been saved!!!</h6>)
                    }
                </Modal>
                {(typeof reference_number === 'object' && reference_number && reference_number.length > 1) &&
                    <div className='showMore'>
                        <span className='text-primary cursor-pointer' onClick={handleShowGroup}>{`Show (${reference_number.length})`}</span>
                        <Modal
                            title='Grouped issue items:'
                            visible={showGroup}
                            onCancel={handleCloseGroup}
                            footer={false}
                            style={{ minWidth: '70%', minHeight: 500 }}
                            className='showMoreGroupModal'
                        >
                            <Spin spinning={loadingGroup} tip='Loading...'>
                                <div className="modalContent">
                                    <table className='IssueItemsTable table'>
                                        <thead>
                                            <tr>
                                                <th>Reference number</th>
                                                <th>Supplier</th>
                                                <th>Product type</th>
                                                <th>Variant</th>
                                                <th>Quantity</th>
                                                <th>Tracking</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!!reference_number && reference_number.length > 0 ? reference_number.map((i, index) => {
                                                const { supplier, variant_data, tracking_number, pushed_at } = Object.assign({}, item)
                                                const { name } = Object.assign({}, supplier)
                                                const { product_type, size, color } = Object.assign({}, variant_data)
                                                return (
                                                    <tr key={`packageItem_${index}`}>
                                                        <td>
                                                            <div className="referenceNumber text-primary">
                                                                <span className='referenceNumberText'>{i}</span>
                                                            </div>
                                                            <div className="pushedAt">{pushed_at ? moment(pushed_at).format('HH:mm DD/MM/YYYY') : ''}</div>
                                                        </td>
                                                        <td>{name}</td>
                                                        <td>{product_type}</td>
                                                        <td>{(size || color) ?
                                                            `${size || ''}${size && color ? '/' : ''}${color || ''}` : ''
                                                        }
                                                        </td>
                                                        <td>{1}</td>
                                                        <td className='text-primary'>{tracking_number}</td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr>
                                                    <td colSpan={7}>No Issue item</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Spin>
                        </Modal>
                    </div>
                }
            </td>
            <td ref={col3Ref} className='fixed-column3' style={{ left: left3 }}>
                {product_type &&
                    <div className="ProductType">{product_type}</div>
                }
                {size &&
                    <div className="variant">{size}</div>
                }
                {error_qty &&
                    <div className="error_qty">Qty: {error_qty}</div>
                }
            </td>
            <td>
                {category &&
                    <div className="text-capitalize font-weight-bold">
                        {category}
                    </div>
                }
                {name &&
                    <div className="issueType">{name}</div>
                }
                {
                    !!shipping_carrier && !!origin_carrier &&
                    <div className="shipmentInfo" style={{ color: '#a9a5a5' }}>{`${shipping_carrier} (${origin_carrier})`}</div>
                }
                {ffm_proof ?
                    hasLinks(ffm_proof) ?
                        <div
                            className='cursor-pointer text-primary'
                            onClick={() => openAnyLinks(ffm_proof)}
                        >
                            <Tooltip title={ffm_proof}>View</Tooltip>
                        </div> :
                        <div className='cursor-pointer text-primary'>
                            <Tooltip title={ffm_proof}>View</Tooltip>
                        </div> : ''
                }
            </td>
            {/* <td className='text-center'>{accepted ? 'Yes' : 'No'}</td> */}
            <td className='colNote'>
                <div className={`${note && note.length > 0 ? 'cursor-pointer NoteHover' : ''} note`}>
                    <Truncate
                        string={note}
                        length={0}
                        text={'Note'}
                    />
                </div>
            </td>
            <td className='text-capitalize text-center'>
                {solution}
                <div className="d-flex justify-content-center">
                    {((solution === 'replace' && resolution === 'replaced') || (solution === 'refund' && resolution === 'refunded')) &&
                        <div>
                            <Tooltip title={`Issue has been ${resolution}.`}>
                                <i className="fa fa-check-circle-o fa-lg text-success mr-2 cursor-pointer" />
                            </Tooltip>
                        </div>
                    }
                    <ListItemsNote key={item._id} id={'PopoverNote_' + item._id} note={item.solution_note} itemId={item._id} title='Solution note' />
                </div>
            </td>
            <td className='text-center'>
                {status ?
                    <Tooltip title={status ? tooltipStatus[status] : ''}>
                        <div className={`status badge ${status}`}>{status}</div>
                    </Tooltip> : ''}
            </td>
            <td>
                <div className="creator">{created_by && created_by.username ? created_by.username : ''}
                    <p className='mb-0'>
                        {created_at ? moment(created_at).format('HH:mm DD/MM/YYYY') : ''}
                    </p>
                </div>
                <div className="approver text-success">{approved_by && approved_by.username ? approved_by.username : ''}
                    <p className='mb-0'>
                        {approved_at ? moment(approved_at).format('HH:mm DD/MM/YYYY') : ''}
                    </p>
                </div>
            </td>
            <td className='text-center'>
                <div className="d-flex flex-column align-items-center">
                    {factory_info && factory_info.confirm ?
                        <div className={`supplierConfirm badge ${factory_info.confirm} text-capitalize`} >
                            {factory_info.confirm === 'half' ? '50/50' : factory_info.confirm}
                        </div> : ''
                    }
                    {
                        partner_refunded &&
                        <div className="refunded-tag">Refunded</div>
                    }
                    <div className="supplierProof mt-1">
                        {factory_info && factory_info.proof ?
                            hasLinks(factory_info.proof) ?
                                <div
                                    className='cursor-pointer text-primary'
                                    onClick={() => openAnyLinks(factory_info.proof)}
                                >
                                    <Tooltip title={factory_info.proof}>View</Tooltip>
                                </div> :
                                <div className='cursor-pointer text-primary'>
                                    <Tooltip title={factory_info.proof}>View</Tooltip>
                                </div> : ''
                        }
                    </div>
                </div>
            </td>
            <td className='colNote'>
                {factory_info && factory_info.note ?
                    <div className={`${factory_info.note && factory_info.note.length > 0 ? 'cursor-pointer NoteHover' : ''} note`}>
                        <Truncate
                            string={factory_info.note}
                            length={0}
                            text={'Note Supplier'}
                        />
                    </div> : ''
                }
            </td>
            {(canEdit || canViewDetails || canDelete) &&
                <td className='fixed-column-right'>
                    <div className="d-flex flex-column align-items-center justify-content-between">
                        <Tooltip title={'Approve'} className='my-1'>
                            <i className="fa fa-check-square-o fa-lg text-success cursor-pointer" onClick={handleShowModal} />
                        </Tooltip>
                        {(canEdit || canViewDetails) &&
                            <Tooltip title={canEdit ? 'Edit' : (canViewDetails ? 'Details' : '')} className='my-1'>
                                <Link to={`/a/package-issues/${package_name}/${category}/${namespace}`} className={`text-primary cursor-pointer`}>
                                    {canEdit ?
                                        <i className="fa fa-pencil fa-lg text-primary" /> :
                                        (canViewDetails ? <i className="fa fa-info-circle fa-lg text-primary" /> : '')
                                    }
                                </Link>
                            </Tooltip>
                        }
                        {canDelete &&
                            (checkDelete() ?
                                <Tooltip title='Delete' className='my-1'>
                                    <span
                                        className="text-danger cursor-pointer"
                                        onClick={(e) => handleClickDelete(e, _id)}
                                    ><i className="fa fa-trash fa-lg text-danger" /></span>
                                </Tooltip> : (
                                    <span
                                        className="text-danger"
                                        style={{ cursor: 'not-allowed' }}
                                    ><i className="fa fa-trash fa-lg text-danger" /></span>
                                )
                            )
                        }
                    </div>
                    <Modal
                        visible={approveModal}
                        title='Approve issue'
                        onCancel={handleCloseModal}
                        className='ApproveIssueModal'
                        footer={(
                            <div>
                                <Button onClick={handleCloseModal}>Cancel</Button>
                                <Button
                                    type="primary"
                                    onClick={onApprove}
                                    disabled={disableApprove || disableApproveBtn || (status === 'done' && partner_refunded)}
                                    className='ml-2'
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    >
                        <div className="mb-3 mr-3 d-flex">
                            <span style={{ minWidth: '92px' }} className='mr-3'>Issue category</span>
                            <Select
                                placeholder='Issue category'
                                value={issueCategory || []}
                                style={{ width: "100%" }}
                                showArrow
                                showSearch
                                options={IssueCategorys}
                                onChange={(value) => handleChangeSelect('issueCategory', value)}
                                allowClear
                                filterOption={(inputValue, option) =>
                                    !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                disabled={source === 'import' || status === 'done' || disableApprove || !!category}
                            />
                        </div>
                        <div className="mb-3 mr-3 d-flex">
                            <span style={{ minWidth: '92px' }} className='mr-3'>Issue type</span>
                            <Select
                                placeholder='Issue type'
                                value={issueType || []}
                                style={{ width: "100%" }}
                                showArrow
                                showSearch
                                options={IssueType}
                                onChange={(value) => handleChangeSelect('issueType', value)}
                                allowClear
                                filterOption={(inputValue, option) =>
                                    !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                disabled={source === 'import' || status === 'done' || disableApprove || !allowEditIssueType}
                            />
                        </div>
                        <div className="mb-3 mr-3 d-flex">
                            <span style={{ minWidth: '92px' }} className='mr-3'>Acceptable</span>
                            <Select
                                placeholder='Acceptable'
                                value={newAccepted || []}
                                style={{ width: "100%" }}
                                showArrow
                                showSearch
                                allowClear
                                options={Accepts}
                                onChange={(value) => handleChangeSelect('newAccepted', value)}
                                filterOption={(inputValue, option) =>
                                    !!option && option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                disabled={status === "done"}
                            />
                        </div>
                        <div className="mb-3 mr-3 d-flex">
                            <span style={{ minWidth: '92px' }} className='mr-3'>Partner refunded</span>
                            <Checkbox
                                checked={partnerRefunded}
                                onChange={handleChangePartnerRefunded}
                                disabled={partner_refunded}
                            />
                        </div>
                    </Modal>
                </td>
            }
        </tr>
    )
}

export default PackageIssuesRow