import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button, Input, Select, message, Spin, Modal, Radio, Space } from 'antd'
import UseDebounce from '../../shared/UseDebounce'
import { getPackageItems, getIssues, upsertPackageIssues } from '../../../services/api/PackageIssuesServices'
import { getLocalData } from '../../../services/StorageServices'
import IssueItemsTable from './IssueItemsTable'
import SelectItemsModal from './SelectItemsModal'
import getHistory from '../../../store/getHistory'
import ItemMockupModal from './ItemMockupModal'

function CreatePackageIssuePage(props) {
    const { id, categoryParams, storeParams } = { ...props }
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const [loading, setLoading] = useState(false)
    const [packageName, setPackageName] = useState(id || '')
    const [listIssueItemsBeforeChange, setListIssueItemsBeforeChange] = useState([])
    const [listIssueItems, setListIssueItems] = useState([])
    const [listStore, setListStore] = useState([])
    const [store, setStore] = useState(storeParams || '')
    const [storeSelect, setStoreSelect] = useState('')
    const [showStoreModal, setShowStoreModal] = useState(false)
    const [supplier, setSupplier] = useState('')
    const [packPushedAt, setPackPushedAt] = useState('')
    const [error, setError] = useState('')
    const [errorValidate, setErrorValidate] = useState({})
    const [selectItemsModal, setSelectItemsModal] = useState(false)
    const [listItem, setListItem] = useState([])
    const [listAddeds, setListAddeds] = useState([])
    const [isMockupOpen, setIsMockupOpen] = useState(false)
    const [imageMockup, setImageMockup] = useState('')
    const [issueCat, setIssueCat] = useState('')
    const [statusPackage, setStatusPackage] = useState('')
    const [orderId, setOrderId] = useState('')
    const [isOpenRefund, setIsOpenRefund] = useState(false)
    const [isPendingRefund, setIsPendingRefund] = useState(false)

    const debouncePackageName = UseDebounce(packageName, packageName === '' ? 500 : 1000)
    const isSupplierAllow = useMemo(() => !!supplier && ['1C', 'TCVN', 'MCUS'].includes(supplier), [supplier])

    const checkReferenceNumber = (value) => {
        return Array.isArray(value) ? value : (value ? [value] : [])
    }

    const toggleStoreModal = () => {
        if (!showStoreModal) {
            setStoreSelect('')
        }
        setShowStoreModal(!showStoreModal)
    }

    const onChangeStoreSelect = (e) => {
        setStoreSelect(e.target.value)
    }

    const onChangeStore = (value) => {
        setStore(value)
    }

    const handleSaveStore = async () => {
        setShowStoreModal(false)
        setStore(storeSelect)
    }

    const handleAddNewItems = async (selected = [], newlistItem = [], issues = [], isShipment, isGroup) => {
        setListItem(newlistItem)
        setListAddeds([...new Set([...listAddeds, ...selected])])

        const groupItems = (items) => {
            const grouped = items.reduce((acc, curr) => {
                const key = `${curr.fulfillment_item}_${curr.barcodeNumber}`
                if (!acc[key]) {
                    acc[key] = { ...curr, quantity: 0, reference_number: [], error_qty: 0 }
                }
                acc[key].quantity += curr.quantity
                acc[key].error_qty += curr.quantity
                acc[key].reference_number.push(typeof curr.reference_number === 'object' ? curr.reference_number[0] : curr.reference_number)
                return acc
            }, {})

            return Object.values(grouped)
        }

        if (isShipment) {
            const newItems = newlistItem.filter((i, index) => selected.includes(index))
            const itemsToAdd = newItems.filter(
                selectedItem => !issues.some(item => {
                    const { reference_number: arr1 = [] } = { ...item }
                    const { reference_number: arr2 = [] } = { ...selectedItem }
                    return arr1.length > 0 && arr2.length > 0 && arr1[0] === arr2[0]
                })
            )
            const itemsToRemove = listItem.filter(
                newItem => !newItems.includes(newItem)
            )
            const updatedItems = issues.filter(
                item => !itemsToRemove.some(removeItem => {
                    const { reference_number: arr1 = [] } = { ...item }
                    const { reference_number: arr2 = [] } = { ...removeItem }
                    return arr1.length > 0 && arr2.length > 0 && arr1[0] === arr2[0]
                })
            )
            setListIssueItems([...new Set([...itemsToAdd, ...updatedItems])])
        } else {
            let newItems = newlistItem.filter((i, index) => selected.includes(index))

            if (isGroup) {
                newItems = groupItems(newItems)
            }

            const itemsToAdd = newItems.filter(
                selectedItem => !listIssueItems.some(item => item.reference_number === selectedItem.reference_number)
            )
            const itemsToRemove = listItem.filter(
                newItem => !newItems.includes(newItem)
            )
            const updatedItems = listIssueItems.filter(
                item => !itemsToRemove.some(removeItem => removeItem.reference_number === item.reference_number)
            )

            setListIssueItems([...new Set([...listIssueItems, ...itemsToAdd, ...updatedItems])])
        }
    }

    const handleShowModal = () => {
        setSelectItemsModal(true)
    }

    const handleCloseModal = () => {
        setSelectItemsModal(false)
    }

    const getDetails = async (packageName, issues, isShipment, namespace) => {
        const payload = {
            packageName,
            issueCat: !!categoryParams ? categoryParams : issueCat
        }
        if (namespace || storeParams) payload.namespace = storeParams ? storeParams : namespace
        setLoading(true)
        setError('')
        setListAddeds([])
        setListStore([])
        try {
            const { success, data, message: mess } = await getPackageItems(payload)
            if (!success) {
                throw new Error(mess)
            }
            const { items = [], fulfillment, namespaces } = { ...data }
            if (namespaces && namespaces.length > 0) {
                if (namespaces.length > 1) {
                    setListStore(namespaces)
                    !showStoreModal && toggleStoreModal()
                    return
                } else {
                    setStore(namespaces[0])
                    return
                }
            }
            const { supplier, pushed_at, factory_info = {}, namespace: ns } = { ...fulfillment }
            const { status } = { ...factory_info }
            const { name } = { ...supplier }
            setPackageName(packageName)
            if (!namespace) setStore(ns)
            setSupplier(name || '')
            setPackPushedAt(pushed_at || '')
            setStatusPackage(status)
            const newItems = items.length > 0 ? items.map(i => {
                const { tracking_company, shipping_carrier } = Object.assign({}, i)
                return {
                    ...i,
                    reference_number: checkReferenceNumber(i.reference_number),
                    error_qty: i.quantity,
                    shipment_info: {
                        origin_carrier: tracking_company,
                        shipping_carrier
                    }
                }
            }) : []
            if (issueCat === "production" || categoryParams === "production") return setListItem(newItems)
            if (issueCat === "shipment" && (!id || id === 'new') && !!namespace && !!newItems.length && newItems.some(i => !!i.tracking_number && !!i.shipment_info && !!i.shipment_info.origin_carrier && !!i.shipment_info.shipping_carrier)) {
                handleAddNewItems([...Array(newItems.length).keys()], newItems, issues, isShipment)
            }

            if (issueCat === "shipment" && !!namespace && !!newItems.length && newItems.some(i => !i.tracking_number || !i.shipment_info || !i.shipment_info.origin_carrier || !i.shipment_info.shipping_carrier)) {
                message.error("There is an item in the package that does not have tracking_number or shipment_info.")
            }

        } catch (error) {
            message.error(error.message || 'Unknown error')
            setError(error.message || 'Unknown error')
        } finally {
            setLoading(false)
        }
    }

    const getlistIssues = async (id, issueCat) => {
        const payload = {
            page: 1,
            limit: 10000,
            query: { package_name: id, issue_cat: issueCat }
        }
        if (store || storeParams) {
            payload.query.store = !!storeParams ? storeParams : store
        } else return

        setLoading(true)
        setError('')
        try {
            const { success, data, message: mess } = await getIssues(payload)
            if (!success) {
                throw new Error(mess)
            }
            const { issues } = { ...data }
            const newList = !!issues && issues.length > 0 ? issues.map(i => {
                const { fulfillment = {}, mockup = '', shipment_info = {}, factory_info = {}, type, category, metadata = {}, resolution = '', request_refund } = Object.assign({}, i)
                const { _id: fulfillmentID = '', order = '' } = Object.assign({}, fulfillment)
                const { _id: typeId } = Object.assign({}, type)
                const { mark_refunded } = Object.assign({}, metadata)

                setIssueCat(category)
                setOrderId(order)

                return {
                    _id: i._id,
                    pushed_at: i.push_at,
                    supplier: i.supplier,
                    package_name: i.package_name,
                    quantity: i.quantity,
                    reference_number: checkReferenceNumber(i.reference_number),
                    variant_data: i.variant_data,
                    error_qty: i.error_qty,
                    note: i.note,
                    tracking_number: i.tracking_number,
                    ffm_proof: i.ffm_proof,
                    solution: i.solution,
                    status: i.status,
                    fulfillment: fulfillmentID,
                    mockup,
                    shipment_info,
                    factory_info,
                    type: typeId,
                    resolution,
                    request_refund,
                    metadata: { ...metadata, mark_refunded },
                    solution_note: i.solution_note,
                }
            }) : []
            setListIssueItems(newList || [])
            const newListCopy = JSON.parse(JSON.stringify(issues))
            setListIssueItemsBeforeChange(newListCopy)
            return newList
        } catch (error) {
            message.error(error.message || 'Unknown error')
            setError(error.message || 'Unknown error')
        } finally {
            setLoading(false)
        }
    }

    const handleChangePackageName = (e) => {
        const { value = '' } = { ...e.target }
        setListItem([])
        setSupplier('')
        setListIssueItems([])
        setListAddeds([])
        if (value !== packageName && value.trim() !== packageName.trim()) {
            setStore('')
        }
        setListStore([])
        setShowStoreModal(false)
        setPackageName(value)
    }

    const handleChangeIssueCat = (value) => {
        setListItem([])
        setSupplier('')
        setListIssueItems([])
        setListAddeds([])
        setListStore([])
        setShowStoreModal(false)
        setIssueCat(value)
    }

    const validateArray = (array) => {
        if (array.some(i => !i.solution)) {
            message.error('Solution is require.')
            return false
        }
        if (issueCat === "production" && array.some(i => !i.error_qty)) {
            message.error('Error qty is require.')
            return false
        }
        if (!!supplier && !(isSupplierAllow || ['1C', 'TCVN', 'MCUS'].includes(supplier)) && issueCat === "production" && array.some(i => (!i.supplier || (i.supplier && !['1C', 'TCVN', 'MCUS'].includes(i.supplier.name))) && !i.type)) {
            message.error('Issue type is require.')
            return false
        }
        return true
    }

    const handleSave = async () => {
        setLoading(true)
        try {
            if (!validateArray(listIssueItems)) return
            const isItemsRefund = listIssueItems.some(i => i.solution === "refund" && (!i.resolution || (!!i.resolution && i.resolution !== "refunded")) && !i.request_refund)
            const isRefundPending = listIssueItems.some(i => {
                const { request_refund = '', metadata = {} } = Object.assign({}, i)
                const { mark_refunded = '' } = Object.assign({}, metadata)

                if (!!mark_refunded) return false
                if (!!request_refund) return true

                return false
            })

            const payload = {
                package_name: packageName,
                category: issueCat,
                issue_items: listIssueItems.map(i => {
                    const { shipment_info = {}, factory_info = {}, supplier = {} } = Object.assign({}, i)
                    const { shipping_carrier, origin_carrier } = Object.assign({}, shipment_info)
                    const { sku_prefix = '' } = Object.assign({}, supplier)
                    const note = i.note && i.note.trim()
                    const ffm_proof = i.ffm_proof && i.ffm_proof.trim()
                    const trackingNumber = i.tracking_number && i.tracking_number.trim()
                    if (!note) delete i.note
                    if (!ffm_proof) delete i.ffm_proof
                    if (!trackingNumber) delete i.tracking_number
                    if (!shipping_carrier && !origin_carrier) delete i.shipment_info
                    if (!!shipping_carrier && !!origin_carrier) {
                        delete i.shipping_carrier
                        delete i.tracking_company
                    }
                    if ((issueCat === "production" && ["1C", "TCVN", "MCUS"].includes(sku_prefix)) || !Object.keys(factory_info).length) delete i.factory_info
                    delete i.resolution
                    delete i.request_refund
                    delete i.metadata
                    return {
                        ...i,
                        supplier: i.supplier._id,
                        package_name: packageName,
                        category: issueCat,
                    }
                })
            }
            if (store) { payload.namespace = store }
            const { success, data, message: mess } = await upsertPackageIssues(payload)
            if (!success) {
                // getlistIssues(packageName, issueCat)
                return message.error(mess)
            }

            if (isRefundPending) {
                setIsPendingRefund(true)
            } else {
                if (isItemsRefund) setIsOpenRefund(true)
            }
            message.success(`${!id || id === 'new' ? 'Add' : 'Edit'} Package Issues success!`)
            if (!id || id === 'new') {
                const history = getHistory()
                const location = {
                    pathname: `/a/package-issues/${packageName.trim()}/${issueCat}/${store.trim()}`,
                }
                history.push(location)
            } else {
                getlistIssues(id, categoryParams)
                getDetails(id, [], false, storeParams)
                // fetchData(store)
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!!id && !!categoryParams) {
            getlistIssues(id, categoryParams)
            getDetails(id, [], false, storeParams)
        }
    }, [id, categoryParams, storeParams])

    const fetchData = async () => {
        if (!!id && !!categoryParams && !!storeParams) return

        if (!!packageName && !!issueCat) {
            const listIssues = await getlistIssues(packageName, issueCat)
            if (!!issueCat && !!packageName) getDetails(packageName, listIssues, true, store)
        }
    }

    useEffect(() => {
        fetchData()
    }, [debouncePackageName, issueCat, store])

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                Modal.destroyAll()
            }
        }

        !!id && isPendingRefund &&
            Modal.warning({
                title: 'Warning',
                content: <span>There is a refund request awaiting approval.<br /> You can not make any request refund now.</span>,
            })
        setIsPendingRefund(false)

        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }

    }, [isPendingRefund])

    const canCreate = permissionsData.includes('ffm_upsert_issue')
    const canEdit = permissionsData.includes('ffm_upsert_issue')

    const _handleDelete = async (item, index) => {
        if (!item._id) {
            const itemsRemoveIdx = listItem.map((i, idx) =>
                (typeof item.reference_number === 'object' && item.reference_number.length > 0 ?
                    item.reference_number.includes(i.reference_number[0])
                    : i.reference_number === item.reference_number) ? idx : undefined
            )
            setListAddeds(listAddeds.filter((i) => !itemsRemoveIdx.includes(i)))
        }
        setListIssueItems(listIssueItems.filter((i, idx) => idx !== index))
    }

    const isSupplierChanged = packPushedAt && listIssueItems && listIssueItems.length > 0 && !!listIssueItems.some((i, index) =>
        (i.supplier && i.supplier.name && i.supplier.name !== supplier) && i.pushed_at !== packPushedAt && index === 0
    )

    const handleLeave = () => {
        setIsMockupOpen(false)
        setImageMockup('')
    }
    const handleHover = (image) => {
        setIsMockupOpen(true)
        setImageMockup(image)
    }

    return (
        <div className='CreatePackageIssuePage'>
            <div className="container-fluid">
                <div className="BackWrapper">
                    <Link className="nav-link BackButton" to="/a/package-issues">
                        <i className="fas fa-chevron-left" />
                        <span>Issues Management</span>
                    </Link>
                </div>
                <div className="MenuTitle d-flex justify-content-between align-items-center">
                    {!id || id === 'new' ? 'Add Package Issues' : 'Edit Package Issues'}
                    <div className="Actions d-flex align-items-center justify-content-end">
                        <Link className='ant-btn ant-btn-default px-4 rounded ml-3 btn-secondary' to='/a/package-issues'>Cancel</Link>
                        {(canCreate || canEdit) &&
                            <Button className="ant-btn ant-btn-primary px-4 rounded ml-3" onClick={handleSave} loading={loading} type="primary">Save</Button>
                        }
                    </div>
                </div>
                <div className="SectionInner mt-3">
                    <div className="p-3">
                        <Spin spinning={loading} tip='Loading...'>
                            <div className="form">
                                <div className="form-item Priority mb-3 d-flex flex-column">
                                    <label className='font-weight-bold'>Issue category:</label>
                                    <div className="PackageName d-flex mb-3">
                                        <Select
                                            placeholder='Select issue category'
                                            style={{ width: 300 }}
                                            value={!issueCat ? null : issueCat}
                                            onChange={handleChangeIssueCat}
                                            allowClear
                                            disabled={!(canCreate || canEdit) || id}
                                            options={[
                                                {
                                                    label: 'Production',
                                                    value: 'production'
                                                },
                                                {
                                                    label: 'Shipment',
                                                    value: 'shipment'
                                                }
                                            ]}
                                        />
                                    </div>
                                    <label className='font-weight-bold'>Package name:</label>
                                    <div className="PackageName d-flex">
                                        <Input
                                            placeholder='Input Package name'
                                            style={{ width: 300 }}
                                            value={!packageName ? null : packageName}
                                            onChange={handleChangePackageName}
                                            allowClear
                                            disabled={!(canCreate || canEdit) || id}
                                        />

                                        {(issueCat === "production") && packageName && supplier && issueCat && (canCreate || canEdit) &&
                                            <Button
                                                size={'large'}
                                                className='ant-btn btn btn-outline-primary rounded ml-3'
                                                onClick={handleShowModal}
                                            >
                                                + Select items
                                            </Button>
                                        }
                                    </div>

                                    {errorValidate.packageName &&
                                        <small className='text-danger'>{errorValidate.packageName}</small>
                                    }
                                    {packPushedAt && listIssueItems && listIssueItems.length > 0 && !!listIssueItems.some((i, index) =>
                                        (i.supplier && i.supplier.name && i.supplier.name !== supplier) && i.pushed_at !== packPushedAt && index === 0
                                    ) &&
                                        <small className='text-danger font-weight-bold mt-2'><i>Supplier has been changed.</i></small>
                                    }
                                </div>
                                {packageName && issueCat &&
                                    <div className="form-item mb-3 d-flex flex-column Priority">
                                        <label className='font-weight-bold'>Store:</label>
                                        <div className="PackageName d-flex">
                                            <Select
                                                placeholder='Store'
                                                value={store || []}
                                                style={{ width: 300 }}
                                                allowClear
                                                disabled={!(canCreate || canEdit) || id || !(listStore && listStore.length > 0) || store}
                                                options={listStore.length > 0 ? listStore.map(i => ({ label: i, value: i })) : []}
                                                onChange={onChangeStore}
                                            />
                                        </div>
                                    </div>
                                }
                                {supplier &&
                                    <div className="form-item mb-3 d-flex flex-column Priority">
                                        <label className='font-weight-bold'>Supplier:</label>
                                        <div className="PackageName d-flex">
                                            <Input
                                                disabled
                                                placeholder='Input Supplier'
                                                value={supplier || ''}
                                                style={{ width: 300 }}
                                                allowClear
                                            />
                                        </div>
                                    </div>
                                }
                                <i className="fa fa-angle-double-down mb-3" style={{ fontSize: 33 }} aria-hidden="true"></i>
                                <div className="form-item mb-3 d-flex flex-column">
                                    <label className='font-weight-bold'>Issue items:</label>
                                    <IssueItemsTable
                                        id={id}
                                        details={listIssueItems}
                                        detailsBefore={listIssueItemsBeforeChange}
                                        handleDelete={_handleDelete}
                                        setListIssueItems={setListIssueItems}
                                        supplierPackage={supplier}
                                        isSupplierChanged={isSupplierChanged}
                                        packPushedAt={packPushedAt}
                                        handleHover={handleHover}
                                        handleLeave={handleLeave}
                                        issueCat={issueCat}
                                        isSupplierAllow={isSupplierAllow}
                                        statusPackage={statusPackage}
                                        orderId={orderId}
                                        isOpenRefund={isOpenRefund}
                                        setIsOpenRefund={setIsOpenRefund}
                                        supplier={supplier}
                                    />
                                </div>
                            </div>
                        </Spin>
                        <SelectItemsModal
                            visible={selectItemsModal}
                            className='SelectItemsModal'
                            title='Check the checkbox to select items that you wish to mark as issues.'
                            style={{ minWidth: '70%', minHeight: 500 }}
                            handleCloseModal={handleCloseModal}
                            okText='Save'
                            packageName={packageName}
                            listItem={listItem}
                            handleAddNewItems={handleAddNewItems}
                            listAddeds={listAddeds}
                            handleLeave={handleLeave}
                            handleHover={handleHover}
                        />
                        <ItemMockupModal
                            visible={isMockupOpen}
                            mockup={imageMockup}
                        />
                        <Modal
                            title='Select store'
                            visible={showStoreModal}
                            okText='Save'
                            onCancel={toggleStoreModal}
                            onOk={handleSaveStore}
                            okButtonProps={{ disabled: !storeSelect }}
                        >
                            <div className="StoreModalContent">
                                <h6 className='mb-0'>We found multiple stores own this package name.</h6>
                                <h6>Please choose one store:</h6>
                                <Radio.Group onChange={onChangeStoreSelect} value={storeSelect}>
                                    <Space direction="vertical">
                                        {listStore && listStore.length > 0 && listStore.map(i => (<Radio key={i} value={i}>{i}</Radio>))}
                                    </Space>
                                </Radio.Group>
                            </div>
                        </Modal>
                        {error && <label className='text-danger'>{error}</label>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreatePackageIssuePage