import React from 'react'
import { Spin } from 'antd'
import emptyData from '../../../statics/assets/images/empty-img-gray.png'
import TableHead from './data/TableHead'
import TableRow from './data/TableRow'
import OrdersError from './OrdersError'
import NoOrders from './NoOrders'
import PrintFilePagination from './PrintFilePagination'
import TableRowNew from './data/TableRowNew'

const PrintFilesTable = (props) => {
    const { loading, data, error, reload, onChangeStatus, allSelected, selectedOrders, onResetSelectOrder, onToggleSelectAll, onUpdateOrdersSelected, suppliers, permissionsData, itemsRequest} = props

    const _renderTableContent = () => {
        if (error) {
            return <OrdersError error={error} />
        }

        if (data.length === 0) {
            return <NoOrders loading={loading} />
        }

        return <TableRowNew
            data={data}
            reload={reload}
            suppliers={suppliers}
            selectedOrders={selectedOrders}
            onChangeStatus={onChangeStatus}
            onToggleSelectAll={onToggleSelectAll}
            onUpdateOrdersSelected={onUpdateOrdersSelected}
            permissionsData={permissionsData}
            itemsRequest={itemsRequest}
        />
    }

    return (
        <div className='PrintFilesTable'>
            <Spin spinning={loading} tip='Loading...'>
                {/* {data.length > 0 ? ( */}
                    <div>
                        <div className="wrapTable">
                            <table className='table table-borderless'>
                                <TableHead
                                    isChecked={allSelected}
                                    selectedOrders={selectedOrders}
                                    onToggleSelectAll={onToggleSelectAll}
                                    onResetSelectOrder={onResetSelectOrder}
                                    onChangeStatus={onChangeStatus}
                                    reload={reload}
                                    permissionsData={permissionsData}
                                />
                                <tbody>
                                    {_renderTableContent()}
                                </tbody>
                            </table>
                        </div>
                        <PrintFilePagination {...props} />
                    </div>
                {/* ) : !loading && (
                    <div className="noData d-flex flex-column align-items-center justify-content-center">
                        <img src={emptyData} alt="No data" />
                        Không có bản ghi!
                    </div>
                )} */}
            </Spin>
        </div>
    )
}

export default PrintFilesTable